import { STATUS_ORDER } from '@monorepo/config';

export const statusExported = [
  STATUS_ORDER.WAITING_FOR_SHIPPING,
  STATUS_ORDER.DELIVERING,
  STATUS_ORDER.COMPLETED,
];

export const ALL_PARAMS_ORDER = [
  {
    key: STATUS_ORDER.WAITING_FOR_PACKAGING,
    offset: 0,
    limit: 10,
    statuses: STATUS_ORDER.WAITING_FOR_PACKAGING,
  },
  {
    key: STATUS_ORDER.WAITING_FOR_EXPORTING,

    offset: 0,
    limit: 10,
    statuses: STATUS_ORDER.WAITING_FOR_EXPORTING,
  },
  {
    key: STATUS_ORDER.WAITING_FOR_ASSEMBLY,
    offset: 0,
    limit: 10,
    statuses: STATUS_ORDER.WAITING_FOR_ASSEMBLY,
  },
  {
    key: 'EXPORTED',
    offset: 0,
    limit: 10,
    statuses: [...statusExported],
  },
];
