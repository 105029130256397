import { Button, Checkbox, Form, Space, Spin } from 'antd';
import { FormInput } from '@monorepo/shared-components';
import { useAuthToken, useLogin } from '@monorepo/hooks';
import { INPUT_TYPE } from '@monorepo/config';
import queryString from 'query-string';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { notify } from '@monorepo/utils';
import './index.scss';

const Login = () => {
  const history = useHistory();
  const { setAuthToken } = useAuthToken();
  const { handleLogin, loading } = useLogin();
  const registerURL = 'https://forms.gle/c83HatwLwAec5h2L9';

  const formFields = [
    {
      formItemOptions: {
        label: 'Email',
        name: 'email',
        rules: [{ required: true, message: 'Vui lòng nhập email !' }],
      },
      inputOptions: {
        placeholder: 'Nhập địa chỉ email của bạn',
        autoFocus: true,
      },
    },
    {
      type: INPUT_TYPE.PASSWORD,
      formItemOptions: {
        label: 'Mật khẩu',
        name: 'password',
        rules: [{ required: true, message: 'Vui lòng nhập mật khẩu !' }],
      },
      inputOptions: {
        placeholder: 'Nhập mật khẩu',
      },
    },
  ];

  async function login(values) {
    try {
      const { accessToken } = await handleLogin(values);
      setAuthToken(accessToken);
      notify.success({
        message: 'Đăng nhập thành công !',
      });
      const parsed = queryString.parse(window.location.search);
      const redirectURL = parsed?.redirectURL;
      if (redirectURL) {
        window.location.href = redirectURL;
      } else {
        history.push('/stock');
      }
    } catch (err) {
      notify.error({
        message: 'Đăng nhập thất bại !',
        description: err?.message,
      });
    }
  }

  return (
    <div className="container">
      <div className="left-col"></div>
      <div className="right-col">
        <Spin spinning={loading}>
          <div className="form">
            <p className="greeting">Xin chào!</p>
            <p className="form-tile">Đăng nhập tài khoản</p>
            <Form layout="vertical" onFinish={(values) => login(values)}>
              {formFields.map((field, index) => {
                return <FormInput key={index} {...field} />;
              })}

              <div className="forgot-password">
                <Checkbox>Ghi nhớ đăng nhập</Checkbox>
                <Link to="/forgot-password">
                  <a>Quên mật khẩu</a>
                </Link>
              </div>

              <Form.Item>
                <Button className="submit-btn" type="primary" htmlType="submit">
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Space>
                <span>Chưa có tài khoản?</span>
                <a href={registerURL} target="_blank" rel="noreferrer">Đăng ký ngay!</a>
              </Space>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Login;
