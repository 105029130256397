import { STATUS_ORDER } from '@monorepo/config';
import { useGetOrders, useGetUserPermissions, useOrderPermissions } from '@monorepo/hooks';
import { PageHeader, Spinner } from '@monorepo/shared-components';
import { buildQueryString, getQuery } from '@monorepo/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page403 from '../../../PageError/403';
import ListStock from './components/ListStock';
import { StatusTabs } from '@monorepo/shared-components';
import { findIndex } from 'lodash';
import useStock from '../hooks';

const StockList = () => {
  const { t } = useTranslation();
  const { loading: loadUserPermissions } = useGetUserPermissions();
  const { canViewOrder } = useOrderPermissions();
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [currentActiveTabKey, setCurrentActiveTabKey] = useState(null);
  const [params, setParams] = useState();
  const swiperRef = useRef(null);
  const GET_QUERY = getQuery();
  const { stockStatus, statusTab, refetchSummaryTabs } = useStock();

  const getParams = (value) => ({
    offset: 0,
    limit: 10,
    statuses: value.statuses,
    shippingStatus: value.shippingStatus,
    query: '',
  });

  const handleChange = (value) => {
    const query = getParams(value);
    // setParams({ ...query });
    buildQueryString({
      params: { ...query },
    });
  };

  const handleChangeActiveIndex = (index) => {
    const statusValue = statusTab[index]?.value;
    setDefaultIndex(index);
    const query = getParams(statusValue);
    setParams({ ...query });
    buildQueryString({
      params: { ...query },
    });
  };

  const getStatusActive = stockStatus({ statuses: statusTab[defaultIndex]?.value?.statuses });
  useEffect(() => {
    // const query = getParams(GET_QUERY);
    // setParams({ ...query });
    const defaultStatus = stockStatus({
      statuses: GET_QUERY.statuses,
    });
    const findDefaultIndex = findIndex(statusTab, { key: defaultStatus });
    setDefaultIndex(findDefaultIndex);
  }, []);

  useEffect(() => {
    const currentTab = statusTab[defaultIndex];
    setCurrentActiveTabKey(currentTab?.key);
  }, [defaultIndex]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loadUserPermissions ? (
        <Spinner loading={loadUserPermissions} />
      ) : canViewOrder ? (
        <>
          <PageHeader pageTitle={t('order.orderList.pageTitle')} />
          <StatusTabs
            onChange={handleChange}
            statusActive={getStatusActive}
            statusTab={statusTab}
            ref={swiperRef}
          />
          <ListStock
            handleChangeActiveIndex={handleChangeActiveIndex}
            currentActiveTabKey={currentActiveTabKey}
            defaultIndex={defaultIndex}
            ref={swiperRef}
            handleOrderStatus={stockStatus}
            refetchCountOrder={refetchSummaryTabs}
          />
        </>
      ) : (
        <Page403 />
      )}
    </>
  );
};

export default StockList;
