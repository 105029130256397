import { Tabs } from 'antd-mobile';
import React, { forwardRef, useEffect, useState } from 'react';
import './index.scss';

export const StatusTabs = forwardRef(({ onChange, statusActive, statusTab }, ref) => {
  const [currentActive, setCurrentActive] = useState();

  useEffect(() => {
    setCurrentActive(statusActive);
  }, [statusActive]);

  function selectCurrentActive({ key, label, value }) {
    setCurrentActive(key);
    onChange && onChange(value);
  }

  return (
    <div className="status-tabs">
      <div className="status-list-container">
        <Tabs
          defaultActiveKey={currentActive}
          activeKey={currentActive}
          onChange={(key) => {
            const index = statusTab.find((item) => item.key === key);
            const findIndex = statusTab.findIndex((item) => item.key === key);
            selectCurrentActive(index);
            ref.current?.swipeTo(findIndex);
          }}
        >
          {statusTab?.map((item, index) => (
            <Tabs.Tab key={item?.key} title={item?.label} />
          ))}
        </Tabs>
      </div>
    </div>
  );
});

export default StatusTabs;
