import { Button } from 'antd';
import './index.scss';
import { forwardRef } from 'react';

import {
  useCompleteAssembly,
  useConfirmExportOrder,
  usePackageOrder,
  useGetOrders,
} from '@monorepo/hooks';
import { notify } from '@monorepo/utils';
import { STATUS_ORDER } from '@monorepo/config';
import PhoneComponent from '../PhoneComponent';
import { t } from 'i18next';
import { SwiperMobile } from '@monorepo/shared-components';
import { ALL_PARAMS_ORDER } from '../../../constant';

const fieldRenderHeader = [
  'id',
  'number',
  'orderPriority',
  'orderType',
  'customerPaymentMethod',
  'status',
  'assemblyRequired',
  'fullStockRequired',
  'note',
  'internalNote',
  'createdBy',
];

const fieldRenderBody = ['items'];

const OrderHeader = ({ orderHeader, refetch }) => {
  const {
    id,
    number,
    orderPriority,
    status,
    assemblyRequired,
    fullStockRequired,
    note,
    internalNote,
    createdBy,
  } = orderHeader;
  const { handlePackage, loading: loadingPackage } = usePackageOrder();
  const { handleConfirmExportOrder, loading: loadingConfirmExportOrder } = useConfirmExportOrder();
  const { handleCompleteAssembly, loading: loadingCompleteAssembly } = useCompleteAssembly();

  const orderPriorityTags = {
    LOW: {
      title: t('order.orderCard.low'),
      color: '#2246DC',
    },
    MEDIUM: {
      title: t('order.orderCard.medium'),
      color: '#00AB78',
    },
    HIGH: {
      title: t('order.orderCard.high'),
      color: '#D2434D',
    },
  };

  const orderStatusTags = {
    WAITING_FOR_PACKAGING: {
      title: t('order.orderStatus.waitingForPackaging'),
      backgroundColor: '#E9EEF5',
      color: '#5B6673',
    },

    WAITING_FOR_EXPORTING: {
      title: t('order.orderStatus.waitingForExporting'),
      backgroundColor: 'rgba(255, 217, 190, 0.59)',
      color: '#EF9351',
    },

    WAITING_FOR_ASSEMBLY: {
      title: t('order.orderStatus.waitingForAssembly'),
      backgroundColor: '#E7F0FF',
      color: '#2246DC',
    },

    WAITING_FOR_SHIPPING: {
      title: t('order.orderStatus.waitingForShipping'),
      backgroundColor: '#EDF9F0',
      color: '#00AB78',
    },
    DELIVERING: {
      title: t('order.orderStatus.waitingForDelivery'),
      backgroundColor: '#EDF9F0',
      color: '#00AB78',
    },
    COMPLETED: {
      title: t('order.orderStatus.completed'),
      backgroundColor: '#EDF9F0',
      color: '#00AB78',
    },
  };

  const renderOrderPriority = (
    <b className="priority" style={{ color: `${orderPriorityTags[orderPriority].color}` }}>
      {`${orderPriorityTags[orderPriority].title}`}
    </b>
  );

  const renderStatus = (
    <div
      className="status-order"
      style={{
        backgroundColor: `${orderStatusTags[status]?.backgroundColor}`,
        color: `${orderStatusTags[status]?.color}`,
      }}
    >
      <b>{orderStatusTags[status]?.title}</b>
    </div>
  );

  const renderButton = () => {
    switch (status) {
      case STATUS_ORDER.WAITING_FOR_PACKAGING:
        return (
          <Button type="primary" onClick={handleClickPackageOrder} loading={loadingPackage}>
            {t('order.orderCard.packaged')}
          </Button>
        );

      case STATUS_ORDER.WAITING_FOR_EXPORTING:
        return (
          <Button
            type="primary"
            onClick={handleClickConfirmExportOrder}
            loading={loadingConfirmExportOrder}
          >
            {t('order.orderCard.exportConfirmation')}
          </Button>
        );

      case STATUS_ORDER.WAITING_FOR_ASSEMBLY:
        return (
          <Button
            type="primary"
            onClick={handleClickCompleteAssembly}
            loading={loadingCompleteAssembly}
          >
            {t('order.orderCard.assemblyConfirmation')}
          </Button>
        );
      default:
        return;
    }
  };

  const handleClickPackageOrder = async (e) => {
    e.stopPropagation();
    try {
      await handlePackage({ orderIDs: [id] }).then((res) => {
        notify.success({
          message: t('order.orderCard.confirmPackageSuccess'),
          placement: 'bottom',
        });
        refetch();
      });
    } catch (e) {
      notify.error({ message: t('order.error'), placement: 'bottom' });
    }
  };

  const handleClickConfirmExportOrder = async (e) => {
    e.stopPropagation();
    try {
      await handleConfirmExportOrder({ orderIDs: [id] }).then(() => {
        refetch();
        notify.success({
          message: t('order.orderCard.confirmExportOrderSuccess'),
          placement: 'bottom',
        });
      });
    } catch (e) {
      notify.error({ message: t('order.error'), placement: 'bottom' });
    }
  };

  const handleClickCompleteAssembly = async (e) => {
    e.stopPropagation();
    try {
      await handleCompleteAssembly({ orderIDs: [id] }).then(() => {
        notify.success({
          message: t('order.orderCard.confirmCompleteAssemblySuccess'),
          placement: 'bottom',
        });
        refetch();
      });
    } catch (e) {
      notify.error({ message: t('order.error'), placement: 'bottom' });
    }
  };

  const renderDescription = (
    <>
      <b>{t('order.orderCard.saleApply')}</b>
      <span>
        {createdBy?.fullname} -{' '}
        <PhoneComponent phoneNumber={createdBy?.telephone} onClick={(e) => e.stopPropagation()} />
      </span>

      <b>{t('order.orderCard.note')}</b>
      <span>
        <span>
          <b>1/</b>
          <span>
            {fullStockRequired
              ? t('order.orderCard.enoughStock')
              : t('order.orderCard.notEnoughStock')}
          </span>
        </span>

        <br />

        <div style={{ display: 'flex' }}>
          <b>2/</b>
          <span>
            <span>{internalNote}</span>
            <br />
            <span>{note}</span>
          </span>
        </div>

        <span>
          <b>3/</b>
          <span>
            {assemblyRequired
              ? t('order.orderCard.hasAssemblyRequired')
              : t('order.orderCard.hasNotAssemblyRequired')}
          </span>
        </span>
      </span>
    </>
  );

  return (
    <div className="header-panel">
      <b>{number}</b>
      {renderOrderPriority}
      {renderStatus}
      <div className="description">{renderDescription}</div>
      <div className="button">{renderButton(status)}</div>
    </div>
  );
};

const OrderBody = ({ orderBody }) => {
  const { items } = orderBody;

  const renderAttributes = (attributes) =>
    attributes?.map((attribute, index) => (
      <span className="product-attribute" key={index}>
        <span>{attribute.attribute.name}:</span>
        <span>{attribute.attributeValue.value}</span>
      </span>
    ));

  const renderProducts = items?.map((item, index) => (
    <div className="card-product" key={index}>
      <b>
        {index + 1}/{item?.productName}
      </b>
      <span className="amount">{`x ${item?.quantity} ${item?.product?.uom?.name || ''}`}</span>
      <span className="product-code">{item?.productCode}</span>
      {renderAttributes(item?.product?.variantAttributeValues)}
    </div>
  ));

  return (
    <div className="body-panel">
      <b className="title">{t('order.orderCard.detailOrder')}</b>
      {renderProducts}
    </div>
  );
};

const ListStock = forwardRef(
  (
    {
      handleChangeActiveIndex,
      defaultIndex,
      currentActiveTabKey,
      handleOrderStatus,
      refetchCountOrder,
    },
    ref
  ) => {
    return (
      <SwiperMobile
        onIndexChange={handleChangeActiveIndex}
        defaultIndex={defaultIndex}
        currentActiveTabKey={currentActiveTabKey}
        allParams={ALL_PARAMS_ORDER}
        useGetOrders={useGetOrders}
        handleOrderStatus={handleOrderStatus}
        OrderHeader={OrderHeader}
        fieldRenderHeader={fieldRenderHeader}
        OrderBody={OrderBody}
        fieldRenderBody={fieldRenderBody}
        ref={ref}
        refetchCountOrder={refetchCountOrder}
        isIncludeOrderItems={true}
      />
    );
    //   const { t } = useTranslation();
    //   const GET_QUERY = getQuery();

    //   const [searchTerm, setSearchTerm] = useState(GET_QUERY.query || '');
    //   const debouncedValue = useDebounce(searchTerm, 500);
    //   const [isMounted, setIsMounted] = useState(false);

    //   useEffect(() => {
    //     const newParams = {
    //       ...params,
    //       pagination: { offset: 0, limit: 10 },

    //       filters: {
    //         ...params.filters,
    //         query: debouncedValue,
    //       },
    //     };
    //     setParams(newParams);
    //   }, [debouncedValue]);

    //   useEffect(() => {
    //     if (isMounted) {
    //       setSearchTerm('');
    //     } else {
    //       setIsMounted(true);
    //     }
    //   }, [GET_QUERY.statuses, isMounted]);

    //   return (
    //     <div className="orders">
    //       <div className="filter-box">
    //         <div className="search-input">
    //           <Input
    //             className="custom-input"
    //             prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
    //             placeholder={t('order.orderList.placeholderInputSearch')}
    //             allowClear={true}
    //             defaultValue={params?.filters?.query}
    //             value={searchTerm}
    //             onChange={(e) => {
    //               setSearchTerm(e.target.value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //       <Space direction="vertical">
    //         <InfiniteScroll
    //           next={fetchMoreData}
    //           dataLength={stocks.length}
    //           hasMore={paginationData?.total === stocks.length ? false : true}
    //           scrollableTarget="scroll"
    //         >
    //           {stocks?.map((stock, index) => (
    //             <Stock key={index} stock={stock} />
    //           ))}
    //         </InfiniteScroll>
    //       </Space>
    //     </div>
    //   );
  }
);

export default ListStock;
