import React, { useEffect } from 'react';
import { getAppRoutes } from '../../routes';

import { LayoutMobile } from '@monorepo/shared-components';
import { useUpdateUserNotification, useWebViewExchange } from '@monorepo/hooks';

const App = () => {
  const { getToken, isFlutterInAppWebViewReady } = useWebViewExchange();
  const { handleUpdateUserNotificationToken } = useUpdateUserNotification();

  useEffect(() => {
    if (isFlutterInAppWebViewReady) {
      getToken().then(token => {
        console.log('token ' + token);
        if (token) {
          handleUpdateUserNotificationToken(token);
        }
      });
    }

  }, [isFlutterInAppWebViewReady])
  return <LayoutMobile getAppRoutes={getAppRoutes} />;
};

export default App;
