import { STATUS_ORDER } from '@monorepo/config';
import { useGetSummaryTabs } from '@monorepo/hooks';
import { t } from 'i18next';

import { statusExported } from './constant';
import { compareTwoArray } from './List/helper';

const useStock = () => {
  const { data, refetch: refetchSummaryTabs } = useGetSummaryTabs();

  const stockStatus = ({ statuses }) => {
    if (statuses === STATUS_ORDER.WAITING_FOR_PACKAGING) {
      return STATUS_ORDER.WAITING_FOR_PACKAGING;
    }

    if (statuses === STATUS_ORDER.WAITING_FOR_EXPORTING) {
      return STATUS_ORDER.WAITING_FOR_EXPORTING;
    }

    if (statuses === STATUS_ORDER.WAITING_FOR_ASSEMBLY) {
      return STATUS_ORDER.WAITING_FOR_ASSEMBLY;
    }

    if (compareTwoArray(statuses, statusExported)) {
      return STATUS_ORDER.EXPORTED;
    }
  };

  const renderTotalStatus = (valueStatus) => (valueStatus !== undefined ? `(${valueStatus})` : '');

  const statusTab = [
    {
      key: STATUS_ORDER.WAITING_FOR_PACKAGING,
      label: `${t('order.orderStatus.waitingForPackaging')} ${renderTotalStatus(
        data?.waitingForPackaging
      )}`,
      value: {
        statuses: STATUS_ORDER.WAITING_FOR_PACKAGING,
      },
    },
    {
      key: STATUS_ORDER.WAITING_FOR_EXPORTING,

      label: `${t('order.orderStatus.waitingForExporting')} ${renderTotalStatus(
        data?.waitingForExporting
      )}`,
      value: {
        statuses: STATUS_ORDER.WAITING_FOR_EXPORTING,
      },
    },
    {
      key: STATUS_ORDER.WAITING_FOR_ASSEMBLY,

      label: `${t('order.orderStatus.waitingForAssembly')} ${renderTotalStatus(
        data?.waitingForAssembly
      )}`,
      value: {
        statuses: STATUS_ORDER.WAITING_FOR_ASSEMBLY,
      },
    },
    {
      key: STATUS_ORDER.EXPORTED,

      label: `${t('order.orderStatus.exported')} ${renderTotalStatus(
        data?.waitingForShipping + data?.delivering + data?.completed
      )}`,
      value: {
        statuses: statusExported,
      },
    },
  ];
  return { stockStatus, statusTab, refetchSummaryTabs };
};

export default useStock;
